<template>
  <div class="course">
    <v-snackbar
      v-model="alert"
      :bottom="y === 'bottom'"
      :color="color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="alert = false">Close</v-btn>
      </template>
    </v-snackbar>
    <div>
      <b>Upload Materi</b>
    </div>
    <v-container fluid>
      <v-row align="center">
        <v-col class="d-flex">
          <v-autocomplete
            @change="checkHasUploadedOrNot"
            label="Pilih Course"
            :items="courses"
            v-model="selectedCourse"
            item-value="id"
            item-text="course_name"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    <div class="file-input">
      <v-file-input
        @change="checkHasUploadedOrNot"
        show-size
        v-model="VideoFile"
        label="Upload Materi"
      >Video/ Materi</v-file-input>
    </div>
    <v-btn :disabled="btnDisable" @click="submitFile" small color="success">Upload File</v-btn>
    <div class="progress">
      <!-- PROGRESS BAR DENGAN VALUE NYA KITA DAPATKAN DARI VARIABLE progressBar -->
    </div>
    <div class="loading-bar-container">
      <v-progress-circular :size="32" color="primary" v-if="loading" :value="progressBar">
        <span class="upload-loading">Sedang Mengupload {{progressBar}} %</span>
      </v-progress-circular>
    </div>
    <div>Allowed File : .pdf,.png,.jpeg,.mp4</div>
    <div>Rekomendasi max size: 20 mb</div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      courses: [
        {
          id: "1",
          course_name: "Loading......."
        }
      ],
      selectedCourse: "",
      VideoFile: [],
      loading: false,
      btnDisable: true,
      progressBar:0,
      alert: false,
      multiline: true,
      message: "",
      x: null,
      y: "top",
      color: "",
      mode: "",
      timeout: 6000
    };
  },
  created() {
    this.getCourses();
  },
  methods: {
    checkHasUploadedOrNot() {
      if (this.VideoFile.length != 0 && this.selectedCourse) {
        this.btnDisable = false;
      }
    },
    getCourses() {
      axios
        .get(process.env.VUE_APP_URL_API + "getAllCourses", {
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(response => {
          this.courses = response.data;
        });
    },
    submitFile() {
      var formData = new FormData();
      formData.append("file", this.VideoFile);
      formData.append("id", this.selectedCourse);
      this.loading = true;
      this.btnDisable = true;
      axios
        .post(process.env.VUE_APP_URL_API + "upload_video", formData, {
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            //DATA TERSEBUT AKAN DI ASSIGN KE VARIABLE progressBar
            /* eslint-disable no-debugger */
            this.progressBar = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          }.bind(this)
        })
        .then(response => {
          this.alert = true;
          this.message = response.data.message;
          this.btnDisable = false;
          this.loading = false;
          this.VideoFile = [];
          this.selectedCourse = "";
        })
        .catch(error => {
          this.message = error.data.message;
        });
    },
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
    }
  }
};
</script>

<style lang="scss" scoped>
.course {
  height: 100%;
  padding: 20px;
  width: 100%;

  b {
    font-size: 24px;
  }
}
.file-input {
  margin-top: 20px;
}

.d-flex.col {
  padding: 0px !important;
}

.container {
  height: 10% !important;
}

.v-text-field {
  max-width: 300px !important;
}

.v-select__slot {
  width: 300px !important;
}

.v-progress-circular > svg {
  margin-left: 50px;
}

.upload-loading {
  margin-left: 100px;
  font-size: 10px;
}

.loading-bar-container {
  margin-left: 10px;
  margin-top: 20px;
}
</style>
