<template>
  <div class="library">
    <div class="title">
      <b>Tabel Progress Watermarking</b>
      <v-autocomplete
        @change="getLibrary"
        label="Filter"
        :items="courses"
        v-model="filter"
        item-value="id"
        item-text="course_name"
        class="filter-history"
      ></v-autocomplete>
    </div>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="data"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:item.path="props">
        <v-btn color="error" class="mx-1" @click="onButtonClick(props.item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn color="success" class="mx-1" @click="downloadMaterial(props.item)">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="dialog" width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Apakah anda yakin menghapus materi ini?</span>
          </v-card-title>
          <v-card-text></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">Tidak</v-btn>
            <v-btn color="error" text @click.prevent="deleteMaterial">Ya</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      libraries: [],
      data: [],
      filter: "all",
      isLoading: true,
      cSel: "A",
      dialog: false,
      columnInfo: "",
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "no"
        },
        {
          text: "Course Name",
          align: "start",
          sortable: false,
          value: "course_name"
        },
        {
          text: "File Name",
          align: "start",
          sortable: false,
          value: "file"
        },
         {
          text: "Student Data",
          align: "start",
          sortable: false,
          value: "student_data"
        },
         {
          text: "Date",
          align: "start",
          sortable: false,
          value: "date"
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status"
        }
      ]
    };
  },
  created() {
    this.getProgress();
  },
  methods: {
    getCourses() {
      axios
        .get(process.env.VUE_APP_URL_API + "getAllCourses", {
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(response => {
          this.courses = response.data;
          this.courses.unshift({
            id: "all",
            course_name: "ALL"
          });
        });
    },
    getProgress() {
      if (this.filter !== undefined) {
        this.data = [];
        axios
          .get(process.env.VUE_APP_URL_API + "getProgressStatus/", {
            headers: {
              Authorization: "Bearer" + " " + localStorage.getItem("token"),
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded"
            }
          })
          .then(response => {
            this.progress = response.data;
            this.progress.forEach((progressList, index) => {
              this.data.push({
                id: progressList.id,
                no: index + 1,
                course_name: progressList.course_name,
                date: progressList.datetime,
                status: progressList.status,
                student_data: progressList.student_data,
                file: progressList.file
              });
            });
            this.isLoading = false;
          });
      }
    },
    onButtonClick(item) {
      this.dialog = true;
      this.columnInfo = item;
    },
    deleteMaterial() {
      axios
        .post(
          process.env.VUE_APP_URL_API + "deleteMaterial/" + this.columnInfo.id,
          "",
          {
            headers: {
              Authorization: "Bearer" + " " + localStorage.getItem("token"),
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        )
        .then(response => {
          console.log(response.data);
          this.data = [];
          this.isLoading = true;
          this.getLibrary();
          this.dialog = false;
        });
    },
    downloadMaterial(item) {
      axios
        .get(process.env.VUE_APP_URL_API + "returnTemporaryURL/" + item.id, {
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(response => {
          window.open(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.library {
  height: 100%;
  width: 100%;
  padding: 20px;
  .title {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .v-input {
      flex: none !important;
    }
  }

  .v-btn {
    min-width: 0px !important;
    width: 5px !important;
  }
  b {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .mdi-delete::before {
    margin-left: 0px !important;
  }
}
</style>
