<template>
  <div class="change-password-container">
    <div>
      <b>Change Password</b>
    </div>
    <v-text-field v-model="old_password" type="password" label="Old Password"></v-text-field>
    <v-text-field
      type="password"
      v-model="new_password"
      :rules="passwordRules"
      label="New Password"
    ></v-text-field>
    <v-text-field
      type="password"
      v-model="c_new_password"
      :rules="confirmPasswordRules"
      label="Confirm New Password"
    ></v-text-field>
    <div class="my-2">
      <v-btn @click="changePassword" depressed medium color="primary">Change Password</v-btn>
    </div>
    <v-snackbar
      v-model="snackbar"
      :bottom="y === 'bottom'"
      :color="color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      old_password: "",
      new_password: "",
      c_new_password: "",
      passwordRules: [
        value => !!value || "Please type password.",
        value => (value && value.length >= 6) || "minimum 6 characters"
      ],
      confirmPasswordRules: [
        value => !!value || "type confirm password",
        value =>
          value === this.new_password ||
          "The password confirmation does not match."
      ],
      color: "",
      mode: "",
      snackbar: false,
      text: "Hello, I'm a snackbar",
      timeout: 6000,
      x: null,
      y: "top"
    };
  },
  methods: {
    changePassword() {
      let formData = new FormData();
      formData.append("old_password", this.old_password);
      formData.append("new_password", this.new_password);
      formData.append("c_new_password", this.c_new_password);

      axios
        .post(process.env.VUE_APP_URL_API + "changePassword", formData, {
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(response => {
          this.old_password = "";
          this.new_password = "";
          this.c_new_password = "";
          this.text = response.data.message;
          this.snackbar = true;
        }).catch(error =>{
          this.text = error.data.message;
          this.snackbar = true;
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.change-password-container {
  height: 100%;
  padding: 20px;

  b {
    font-size: 24px;
  }
}
</style>
