<template>
  <v-app id="inspire">
    <v-snackbar
      v-model="alert"
      :bottom="y === 'bottom'"
      :color="color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="alert = false">Close</v-btn>
      </template>
    </v-snackbar>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Login form</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form type="submit">
                  <v-text-field
                    label="Username"
                    name="username"
                    v-model="form.username"
                    prepend-icon="mdi-account"
                    type="text"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    @keyup.enter.native="adminLogin"
                    v-model="form.password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="adminLogin" color="primary">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { mapActions } from "vuex";
import Vuex from "vuex";
Vue.use(Vuex);

export default {
  name: "Login",
  data() {
    return {
      multiline: true,
      message: "",
      x: null,
      y: "top",
      color: "",
      mode: "",
      alert: false,
      timeout: 2000,
      isDisable: true,
      dismissSecs: 5,
      dismissCountDown: 0,
      showAlert: false,
      disableButton: false,
      form: {
        username: "",
        password: ""
      },
      error: "",
      formLogin: []
    };
  },
  methods: {
    ...mapActions({
      setTokenn: "setTokenn",
      setName: "setName"
    }),
    adminLogin() {
      var self = this;
      if (this.form.username != "" && this.form.password != "") {
        let formData = new FormData();

        formData.append("username", this.form.username);
        formData.append("password", this.form.password);
        axios
          .post(process.env.VUE_APP_URL_API + "login", formData)
          .then(function(data) {
            self.setTokenn(data.data.success.token);
            self.setName(data.data.success.name);
            localStorage.setItem("name", data.data.success.name);

            self.$router.push({
              name: "insert"
            });
          })
          .catch(function() {
            self.message = "Username atau Password Salah ";
            self.alert = true;
          });
      } else {
        self.message = "Username dan Password Tidak boleh kosong ";
        self.alert = true;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
// #app {
//   display:flex;
//   justify-content:center;
// }
</style>
