<template class="termo123">
  <div class="height-100">
    <div class="video-watermark">
      <div class="super-container">
        <embed class="pdf-watermark" :src="this.material_path" width="800px" height="2100px" />
        <!-- <video :v-if="data.type=='mp4'" id="videoElement" @canplay="updatePaused" @playing="updatePaused" @pause="updatePaused" controls> -->
            <!-- <source :src="data.material_path" />
        </video> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      videoElement: null,
      paused: null,
      students: [],
      selectedCourse: "",
      selectedVideo: "",
      videos: [],
      data: '',
      material_path: ''
    };
  },
  created() {
    this.getVideos();
  },
  computed: {
    playing() {
      return !this.paused;
    }
  },
  methods: {
    play() {
      this.videoElement.play();
    },
    pause() {
      this.videoElement.pause();
    },
    updatePaused(event) {
      this.videoElement = event.target;
      this.paused = event.target.paused;
    },
    getVideos() {
      axios
        .get(
          process.env.VUE_APP_URL_API +
            "getVideos/" +
            this.$route.params.course_id +
            "/" +
             this.$route.params.material_id +
            "/" +
            this.$route.params.id
        )
        .then(response => {
          this.data = response.data;
          this.material_path = process.env.VUE_APP_URL_STORAGE + this.data.path
          window.location.href =this.material_path

        });
    }
  }
};
</script>

<style lang="scss" scoped>
.video-watermark {
  width: 100%;
  height: 100%;
}
html{
    height:100%;
}
.super-container {
  height: 100%;
}
body {
  height: 100%;
}
.pdf-watermark {
  width: 100%;
  height: 100%;
}
.height-100{
  height:100%;
}
div:has(height-100) {
  height:100%;
}
</style>
