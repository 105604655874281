<template>
  <v-app id="generate-watermark">
    <v-snackbar
      v-model="alert"
      :bottom="y === 'bottom'"
      :color="color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
    <div class="title">
      <b>Generate Watermark Per File</b>
    </div>
    <div class="select-container">
      <b>Nama Course</b>
      <v-container fluid>
        <v-row align="center">
          <v-col class="d-flex">
            <v-autocomplete
              label="Course Name"
              @change="getVideoMaterialList"
              :items="courses"
              v-model="selectedCourse"
              item-value="id"
              item-text="course_name"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div>
      <div>
        <div class="select-container">
          <b>Nama Materi</b>
          <v-container fluid>
            <v-row align="center">
              <v-col class="d-flex">
                <v-autocomplete
                  label="File"
                  aria-required
                  @change="getListStudentWithStatus"
                  :items="videos"
                  v-model="selectedVideo"
                  item-value="id"
                  item-text="video_filename"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <b>{{emptyStudent}}</b>
          <v-checkbox
            @change="checkIfEmptyOrNot"
            @click="checkAll"
            v-model="selectAll"
            v-if="students.length!==0"
            label="Select All"
          />
          <div v-if="students.length!==0" class="break-apart">
            <div v-for="(student,studentIndex) in students" :key="studentIndex">
              <v-checkbox
                @change="checkIfEmptyOrNot"
                v-model="student.status"
                :label="student.fullname + ' - ' + student.username"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-2">
      <v-btn
        :disabled="btnDisable"
        @click="generateWatermark"
        depressed
        small
        color="primary"
      >Generate</v-btn>
    </div>
    <v-progress-circular v-if="loading" :size="50" color="primary" indeterminate></v-progress-circular>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      btnDisable: true,
      alert: false,
      message: "",
      emptyStudent: "",
      multiline: true,
      x: null,
      y: "top",
      color: "",
      mode: "",
      timeout: 6000,
      courses: [
        {
          id: "1",
          course_name: "Loading........"
        }
      ],
      students: [],
      selectedCourse: "",
      selectedVideo: "",
      videos: [],
      selectAll: false
    };
  },

  created() {
    this.getCourses();
  },
  methods: {
    checkIfEmptyOrNot() {
      if (this.selectedCourse && this.selectedVideo) {
        this.btnDisable = false;
      }
    },
    getCourses() {
      axios
        .get(process.env.VUE_APP_URL_API + "getAllCourses", {
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(response => {
          this.courses = response.data;
        });
    },
    getVideoMaterialList() {
      this.students = [];
      axios
        .get(
          process.env.VUE_APP_URL_API + "getAllMaterial/" + this.selectedCourse,
          {
            headers: {
              Authorization: "Bearer" + " " + localStorage.getItem("token"),
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        )
        .then(response => {
          this.videos = response.data;
        });
    },
    getListStudentWithStatus() {
      var formData = new FormData();
      formData.append("material_library_id", this.selectedVideo);
      formData.append("course_id", this.selectedCourse);
      axios
        .post(
          process.env.VUE_APP_URL_API + "getListStudentWithStatus",
          formData,
          {
            headers: {
              Authorization: "Bearer" + " " + localStorage.getItem("token"),
              Accept: "*"
            }
          }
        )
        .then(response => {
          if (!response.data.length) {
            this.emptyStudent =
              "Tidak ada partisipan baru yang belum tergenerate";
          } else {
            this.emptyStudent = "";
          }
          this.students = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    checkAll() {
      if (this.selectAll) {
        for (var index = 0; index < this.students.length; index++) {
          this.students[index].status = true;
        }
      } else {
        for (
          var falseIndex = 0;
          falseIndex < this.students.length;
          falseIndex++
        ) {
          this.students[falseIndex].status = false;
          this.selectAll = false;
        }
      }
    },
    generateWatermark() {
      let self = this;
      self.btnDisable = true;
      self.loading = true;
      var students = [];

      for (var index = 0; index < self.students.length; index++) {
        if (self.students[index].status) {
          students.push({
            id: self.students[index].id
          });
        }
      }
      let formData = new FormData();
      formData.append("id", self.selectedCourse);
      formData.append("students", JSON.stringify(students));
      formData.append("material_library_id", self.selectedVideo);
      axios
        .post(process.env.VUE_APP_URL_API + "generateWatermark", formData, {
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(response => {
          self.alert = true;
          self.message = response.data.message;
          self.selectedCourse = "";
          self.selectedVideo = "";
          self.videos = [];
          self.students = [];
          self.btnDisable = false;
          self.loading = false;
          self.selectAll = false;
        }).catch(function() {
          self.alert = true;
          self.message = "Gagal generate, Silakan Coba Lagi"
          self.selectedCourse = "";
          self.selectedVideo = "";
          self.videos = [];
          self.students = [];
          self.btnDisable = false;
          self.loading = false;
          self.selectAll = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
#generate-watermark {
  padding: 20px;

  .break-apart {
    height: 200px;
    overflow-y: scroll;
    margin-bottom: 15px;
  }

  .v-input--selection-controls {
    margin: 0px !important;
    padding: 0px !important;
  }

  .title {
    font-size: 32px;
    margin-top: 20px;
  }

  .select-container {
    margin-top: 20px;
  }

  .generate-btn {
    margin-top: 20px;
  }

  .d-flex.col {
    padding: 0px !important;
  }

  .course {
    width: 500px;
  }

  .v-text-field {
    max-width: 300px !important;
  }

  .v-select__slot {
    width: 300px !important;
  }
}
</style>
