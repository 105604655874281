<template>
  <div class="history">
    <div class="title">
      <b>Tabel Histori Generate Watermark</b>
      <v-autocomplete
        @change="getHistory"
        label="Filter"
        :items="courses"
        v-model="filter"
        item-value="id"
        item-text="course_name"
        class="filter-history"
      ></v-autocomplete>
    </div>
    <v-snackbar
      v-model="alert"
      :bottom="y === 'bottom'"
      :color="color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="alert = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-data-table
      :headers="headers"
      :items="data"
      item-key="no"
      :page.sync="page"
      :loading="isLoading"
      v-model="selectedRow"
      hide-default-footer
      @click:row="clicked"
      class="elevation-1 row-pointer"
    ></v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        @input="nextPage"
        :length="pageCount"
      ></v-pagination>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialog" width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Link Materi untuk di copy</span>
          </v-card-title>
          <v-card-text>
            <div>
              <b>{{ link }}</b>
              <span>
                <v-icon @click="copyText">mdi-content-copy</v-icon>
              </span>
            </div>
            <div class="student">
              Student / Participant yang sudah tergenerate
            </div>
            <div v-for="(student, index) in students" :key="index">
              <div>
                <span
                  >{{ student.fullname }} - {{ student.username }} -
                  {{ student.status }}</span
                >
                <input type="hidden" id="myInput" :value="link" />
                <v-icon
                  v-if="student.status === 'Success'"
                  v-on:click="deleteFile($event, student.id)"
                  >mdi-delete</v-icon
                >
                <v-icon
                  v-if="student.status === 'Success'"
                  v-on:click="getGeneratedVideo($event, student.id)"
                  >mdi-eye</v-icon
                >
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false"
              >Tutup</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      link: "",
      isLoading: false,
      expanded: [],
      dialog: false,
      page: 1,
      pageCount: 0,
      courses: [],
      singleExpand: false,
      libraries: [],
      selectedRow: [],
      students: [],
      rememberStudent: "",
      multiline: true,
      message: "",
      x: null,
      y: "top",
      color: "",
      mode: "",
      alert: false,
      timeout: 2000,
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "no",
        },
        {
          text: "Course Name",
          align: "start",
          sortable: false,
          value: "course_name",
        },
        {
          text: "File Mentah",
          align: "start",
          sortable: false,
          value: "file",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
        },
      ],
      data: [],
      filter: "all",
      offset:0
    };
  },
  created() {
    this.getHistory();
    this.getCourses();
  },
  methods: {
    getCourses() {
      axios
        .get(process.env.VUE_APP_URL_API + "getAllCourses", {
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          this.courses = response.data;
          this.courses.unshift({
            id: "all",
            course_name: "ALL",
          });
        });
    },
    clicked(value) {
      const index = this.expanded.indexOf(value);
      if (index === -1) {
        this.expanded.push(value);
      } else {
        this.expanded.splice(index, 1);
      }
      this.rememberStudent = value;
      this.getStudent(value);
    },
    deleteFile: function (event, id) {
      var self = this;
      console.log(event);
      axios
        .post(process.env.VUE_APP_URL_API + "deleteVideoWatermark/" + id, "", {
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          console.log(response);
          self.getStudent(self.rememberStudent);
          self.data = [];
          self.getHistory();
          self.alert = true;
          self.message = "Berhasil Di hapus";
        })
        .catch((error) => {
          self.alert = true;
          self.message = error.message;
        });
    },
    getStudent(value) {
      this.isLoading = true;
      axios
        .get(
          process.env.VUE_APP_URL_API +
            "getStudentHistory/" +
            value.course_id +
            "/" +
            value.material_library_id,
          {
            headers: {
              Authorization: "Bearer" + " " + localStorage.getItem("token"),
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          this.dialog = true;
          this.link = response.data.data;
          this.isLoading = false;
          this.students = response.data.students;
          if (this.students.length === 0) {
            this.dialog = false;
            this.data = [];
            this.getHistory = "";
          }
        });
    },
    copyText() {
      /* Get the text field */
      let copyToClipboard = document.querySelector("#myInput");
      copyToClipboard.setAttribute("type", "text"); // 不是 hidden 才能複製
      copyToClipboard.select();
      try {
        document.execCommand("copy");

        this.alert = true;
        this.message = "Copied to Clipboard";
      } catch (err) {
        alert("Oops, unable to copy");
      }
      copyToClipboard.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    getGeneratedVideo: function (event, id) {
      axios
        .get(process.env.VUE_APP_URL_API + "viewGeneratedVideo/" + id, {
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          this.material_path =
            process.env.VUE_APP_URL_STORAGE + response.data.path;
          window.open(this.material_path);
        });
    },
    newFilter(){
      this.offset = 0
      this.getHistory()
    },
    getHistory() {
      if (this.filter !== undefined) {
        this.data = [];
        this.isLoading = true;
        axios
          .get(
            process.env.VUE_APP_URL_API +
              "watermarkHistory/" +
              this.filter +
              "/" +
              this.offset,
            {
              headers: {
                Authorization: "Bearer" + " " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then((response) => {
            this.histories = response.data.data;
            this.pageCount = response.data.total;
            let no = this.page * 10 - 9;
            this.histories.forEach((library, index) => {
              this.data.push({
                no: no + index,
                material_library_id: library.material_library_id,
                course_id: library.id,
                course_name: library.course_name,
                status: library.status,
                link_video: library.link_video,
                file: library.file,
                student: [],
              });
            });
            this.isLoading = false;
          });
      }
    },
    nextPage(page) {
      this.isLoading = true;
      if (page === 1) {
        this.offset = 0;
      } else {
        this.offset = page * 10 - 10;
      }
      this.getHistory();
    },
  },
};
</script>

<style lang="scss">
.text-start {
  font-size: 16px !important;
  cursor: pointer !important;
}
.v-dialog {
  width: 900px !important;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.container {
  height: 10% important;
}

.row.align-center.justify-center {
  height: 100%;
}

.history {
  width: 100%;
  height: 100%;
  padding: 20px;

  .title {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .filter-history {
      .v-input__slot {
        width: 300px;
      }
    }
    .v-input {
      flex: none !important;
    }
  }

  table {
    width: 100%;
    height: 100%;
  }
}
#myInput {
  margin-right: 10px;
}
.mdi-content-copy::before,
.mdi-delete::before,
.mdi-eye::before {
  font-size: 17px !important;
  margin-bottom: 3px;
  margin-left: 5px;
  cursor: pointer;
}
.student {
  font-size: 20px !important;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: 600;
}
</style>
