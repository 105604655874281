import Insert from './components/course/Insert.vue';
import Generate from './components/course/Generate.vue'
import VueRouter from 'vue-router';
import View from './components/course/View.vue'
import Login from './components/Login.vue'
import Library from './components/course/Library.vue'
import History from './components/course/History.vue'
import ChangePassword from './components/ChangePassword.vue'
import Progress from './components/course/Progress.vue'
import Vue from 'vue';

const routes = [{
        path: '*',
        component: Insert,
        name: 'insert',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/Generate',
        component: Generate,
        name: 'generate',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/History',
        component: History,
        name: 'history',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/Progress',
        component: Progress,
        name: 'progress',
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/library',
        component: Library,
        name: 'library',
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/view/:course_id/:material_id/:id',
        component: View,
        name: 'view',
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/ChangePassword',
        component: ChangePassword,
        name: 'change_password',
        meta: {
            requiresAuth: false
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

Vue.use(VueRouter);
router.beforeEach((to, from, next) => {
    if (to.path != '/login') {
        if (localStorage.getItem('token') == null && to.name !== 'view') {
            next('login');
        } else {
            next();
        }
    } else if (to.path == '/login' && localStorage.getItem('token')) {
        next({
            name: 'insert'
        });
    } else {
        next();
    }
    next();
})

export default router;