import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    name: localStorage.getItem('name') || '',
  },
  mutations: {
    SET_TOKEN(state,token) {
      state.token = token
    },
    SET_NAME(state,name) {
      state.name = name
    }
  },
  actions: {
    setTokenn(context, payload) {
      context.commit('SET_TOKEN', payload)
      localStorage.setItem('token',payload)
    },
    setName(context, payload) {
      context.commit('SET_NAME', payload)
      localStorage.setItem('name',payload)
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    name: state => state.name
  }
})
