<template>
  <div>
    <v-app v-if="isAuth && checkRoutes" id="inspire">
      <v-navigation-drawer v-model="drawer" app clipped>
        <v-list dense>
          <v-list-item to="/" link>
            <v-list-item-action>
              <v-icon>mdi-cloud-upload</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Upload Material</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/library" link>
            <v-list-item-action>
              <v-icon>mdi-library</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Material Library</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/Generate" link>
            <v-list-item-action>
              <v-icon>mdi-video</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Generate Watermark</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/History" link>
            <v-list-item-action>
              <v-icon>mdi-history</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>History Watermark</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/ChangePassword" link>
            <v-list-item-action>
              <v-icon>mdi-lock-reset</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Change Password</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout" link>
            <v-list-item-action>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app clipped-left>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>
          <div class="admin-navbar">
            <span>Hi {{name}}</span>
            <v-img max-height="85" max-width="100" src="./assets/images/indomaret.png"></v-img>
          </div>
        </v-toolbar-title>
      </v-app-bar>

      <v-main>
        <v-container class="fill-height" fluid>
          <router-view />
        </v-container>
      </v-main>

      <v-footer app>
        <span>&copy; {{ new Date().getFullYear() }}</span>
      </v-footer>
    </v-app>
    <router-view v-if="!isAuth || !checkRoutes" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    drawer: null,
    isLogin: false
  }),

  computed: {
    ...mapGetters({
      isAuth: "isAuthenticated",
      name: "name"
    }),
    checkRoutes() {
      return this.$router.currentRoute.name !== "view";
    }
  },
  methods: {
    logout() {
      localStorage.clear();
      location.reload();
    }
  }
};
</script>

<style lang="scss">
table.v-table thead th {
  font-size: 20px !important;
}

table.v-table tbody td {
  font-size: 18px !important;
}
.v-toolbar__title {
  width: 100% !important;
}
.admin-navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.v-toolbar__content {
  background-color: rgb(36, 160, 237);
}
</style>
